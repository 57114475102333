.container {
    display: flex;
    flex-direction: row;
    width: 900px;
    max-width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.collapsibleLabel {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    cursor: default;
    text-align: start;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -o-user-select: none;
    user-select: none; /* Non-prefixed version, currently */
}

.content {
    width: 100%;
    overflow: hidden;
    transition: height ease 0.9s;
}

.contentContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
.expand {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
}
.expandIcon {
    height: 24px;
    width: 24px;
    font-size: 24px;
    cursor: pointer;
}

.separator {
    height: 1px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
}

@media print {
    .content {
        height: auto !important;
    }
}
