.loaderContainer {
    height: 100%;
    width: 100%;
    display: flex;
}
.loader {
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
