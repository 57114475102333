.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 4px;
}

.documentIcon {
    font-size: 26px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.label,
.downloadableLbl {
    font-size: 1rem;
    margin-left: 2px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 80px);
}
.downloadableLbl {
    cursor: pointer;
}
.downloadableLbl:hover {
    text-decoration: underline;
}
.deletBtn {
    font-size: 26px;
    height: 26px;
    margin-left: 5px;
    color: '#787878';
    cursor: pointer;
}
.loaderContainer {
    position: relative;
    height: 26px;
    width: 26px;
}

.iconContainer {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
