.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100% - 20px);
    padding: 5px 10px;
    padding-bottom: 15px;
}
.labelLabel {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
}
.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepDescriptionLabel {
    font-size: 0.75rem;
    margin-top: 5px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    min-height: 20px;
}
