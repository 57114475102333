.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconContainer,
.disabledIconContainer {
    font-size: 60px;
    height: 60px;
    color: var(--eureka-outline);
}

.iconContainer:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: var(--eureka-primary);
}
