.container {
    width: fit-content;
    max-width: calc(100% - 20px);
    display: flex;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 0px;
    width: 100%;
    flex-direction: column;
}

.labelLabel {
    font-size: 16px;
    margin-left: 8px;
    margin-bottom: 5px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.stepDescriptionLabel {
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: -4px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filesInput {
    display: none;
}
.btnContainer {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 3px;
    width: fit-content;
}
.errorMsg {
    font-size: 0.75rem;
    margin-top: 5px;
    margin-left: 14px;
}

.filesContainer {
    padding-top: 5px;
}
