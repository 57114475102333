.pillContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding: 5px 10px;
}

.listContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
}

.titleLbl {
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
}

.descriptionPar {
    font-size: 16px;
    white-space: pre-wrap;
    cursor: default;
}

.btnContainer {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 3px;
    width: fit-content;
}

.errorMsg {
    font-size: 0.75rem;
    margin-top: 5px;
    margin-left: 15px;
}
