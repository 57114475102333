.confirmationContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
}

.container {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
}
.confirmationContainer,
.confirmationContainer *,
.confirmationContainer *::after,
.confirmationContainer *::before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.checkContainer {
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
}

.messageContainer {
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.mobileLinkContainer {
    font-size: 1.1rem;
    margin-left: 15px;
    margin-top: 10px;
    padding-right: 5px;
}

.linkContainer {
    font-size: 1.2rem;
    margin-left: 20px;
    margin-top: 20px;
    padding-right: 10px;
}

.url {
    font-weight: bolder;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1rem;
    max-width: calc(100% - 100px);
    word-break: break-all;
}
.closeIcon {
    right: 15px;
    top: 10px;
    font-size: 26px;
    position: absolute;
    cursor: pointer;
}
