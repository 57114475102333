.firstLvlContainer {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.smallSeparator {
    height: 0;
    max-width: 100%;
    flex-basis: 0;
}

.separator {
    flex-basis: 100%;
    height: 0;
}
