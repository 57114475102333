.containerContainer {
    margin-top: 10px;
}

.container,
.mobileContainer {
    background-color: #f0f0f0;
    border: 1px solid #d6d6d6;
    width: fit-content;
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.container {
    max-width: calc(100% - 112px);
    padding-right: 55px;
    padding-left: 55px;
}

.mobileContainer {
    max-width: calc(100% - 42px);
    padding-right: 20px;
    padding-left: 20px;
}

.titleLbl {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    margin-left: 10px;
    margin-bottom: 10px;
}

.contentContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
}

.btnsContainer {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.deleteBtn,
.disabledDeleteBtn {
    font-size: 24px;
}

.deleteBtn {
    cursor: pointer;
    height: 24px;
}
