.container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}
.checkboxLbl,
.overflowLbl {
    font-size: 18px;
    margin-right: 10px;
    margin-left: 10px;
}
.overflowLbl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.labelCheckBoxContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
}

.descriptionPar {
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: 4px;
    font-weight: 400;
    line-height: 1.66;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
