.EF-DraftContainer {
    font: inherit;
    font-size: 1rem;
}
.postview-editor {
    cursor: default !important;
}
.EF-DraftContainer .DraftEditor-root {
    height: unset;
}

.EF-DraftContainer .rdw-option-wrapper {
    border-radius: 7px;
    border: 1px solid var(--eureka-outline);
    background-color: transparent;
}

.EF-DraftContainer .rdw-option-wrapper:active {
    border: 1px solid var(--eureka-primary);
}
.EF-DraftContainer .rdw-option-wrapper:hover {
    box-shadow: none;
    border: 1px solid var(--eureka-primary);
}

.EF-DraftContainer .rdw-option-active {
    border: 1px solid var(--eureka-primary);
    box-shadow: none;
}

.EF-DraftContainer .rdw-editor-toolbar {
    border-radius: 15px 15px 0 0;
    border: none;
    border-bottom: 1px solid var(--eureka-outline);
    background-color: transparent;
}

.postview-editor-toolbar {
    pointer-events: none;
}

.EF-DraftContainer .rdw-editor-main {
    cursor: text;
    padding-top: 12px;
    padding-bottom: 40px;
    margin-right: 0px;
}

.EF-DraftContainer .public-DraftStyleDefault-block {
    margin: 0px 0;
    max-width: 100%;
}

.EF-DraftContainer .rdw-image-imagewrapper {
    max-width: 100%;
    /* cursor: pointer; */
    position: relative;
}

.EF-DraftContainer .rdw-image-alignment-editor {
    padding-right: 10px;
    padding-left: 5px;
    margin-left: -5px;
}
.EF-DraftContainer .rdw-editing-image {
    border: 1px solid var(--secondary);
}

.EF-DraftContainer .DraftEditor-root figure {
    margin: 0px;
}

.EF-DraftContainer .rdw-image-alignment-option {
    font-size: 16px !important;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--light-grey);
}

.EF-DraftContainer .rdw-image-alignment-options-popup {
    width: fit-content;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
}

/* Hides empty placeholder */
.EF-DraftContainer
    .RichEditor-hidePlaceholder
    .public-DraftEditorPlaceholder-root {
    display: none;
}
