.confirmationContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
}

.container {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
}
.contentContainer {
    padding: 0px 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.confirmationContainer,
.confirmationContainer *,
.confirmationContainer *::after,
.confirmationContainer *::before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.warningContainer {
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
}

.messageContainer {
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 1.2rem;
    text-align: center;
}

.btnContainer {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.closeIcon {
    right: 15px;
    top: 10px;
    font-size: 26px;
    position: absolute;
    cursor: pointer;
}
