.standAloneFormContainer {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 60px);
    display: flex;
    overflow-x: hidden;
}

.fullScreenStandAloneForm {
    width: 100%;
    padding: 20px;
    min-height: 100%;
    overflow: hidden;
}

.standAloneFormCard {
    -webkit-box-shadow: -6px 0px 8px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -6px 0px 8px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -6px 0px 8px 5px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
    width: fit-content;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 30px;
}

.widgetFormContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;
    width: fit-content;
    max-width: 100%;
}
