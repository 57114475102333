.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    position: relative;
}
.curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.submitBtnContainer {
    margin-top: 10px;
    width: 200px;
    max-width: calc(100% - 40px);
}
