.customBtn {
    max-width: 100%;
    font-size: 1.4rem;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customBtn[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.ripple {
    width: 50px;
    height: 50px;
    position: absolute;
    display: block;
    content: '';
    border-radius: 9999px;
    opacity: 1;
    animation: 1s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    50% {
        transform: scale(10);
        opacity: 0.1;
    }
    60% {
        transform: scale(35);
        opacity: 0;
    }
    100% {
        transform: scale(35);
        opacity: 0;
    }
}

.loaderContainer {
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    position: absolute;
}
