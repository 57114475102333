.container,
.mobileContainer {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-right: auto;
    position: relative;
    padding-top: 10px;
    padding-bottom: 5px;
    max-width: 100%;
}
.bulletContainer {
    display: flex;
    margin-left: 30px;
    margin-right: 10px;
    align-items: center;
}
.bullet {
    border-width: 2px;
    border-style: solid;
    margin-bottom: 2px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-left: 10px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
}

.titleLbl {
    width: 100%;
    height: 28px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    margin-bottom: 10px;
}

.title {
    height: 28px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.contentContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    height: fit-content;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.deleteBtn,
.disabledDeleteBtn {
    display: flex;
    font-size: 24px;
    margin-right: 8px;
    margin-left: auto;
}

.deleteBtn {
    cursor: pointer;
}
