.container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    position: relative;
}
.curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.btnsContainer {
    margin-top: 10px;
    max-width: calc(100% - 40px);
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 20px;
}
