.curtain {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 5000;
}

#widget:eureka {
    min-height: inherit;
}
.container {
    width: 100%;
    position: relative;
}

.widgetContainer {
    position: relative;
    max-width: 100%;
    height: 100%;
    min-height: inherit;
}

.container *,
.container *::after,
.container *::before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
