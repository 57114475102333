.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
}
.checkboxContainer {
    margin-left: 12px;
}
.messageContainer {
    margin: 0px;
    max-width: calc(100% - 60px);
    font-size: 1rem;
    padding-top: 7px;
}

.highlight {
    font-weight: bold;
    cursor: pointer;
}
.dialogContainer {
    overflow-y: hidden;
    max-height: 60vh;
    min-width: 400px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.dialogContainer,
.dialogContainer *,
.dialogContainer *::after,
.dialogContainer *::before {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.closeIcon {
    right: 15px;
    top: 10px;
    font-size: 26px;
    position: absolute;
    cursor: pointer;
}
.dialogTitle {
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
    padding-bottom: 5px;
    width: calc(100% - 40px);
    margin-bottom: 5px;
    text-align: center;
}
.dialogContentContainer {
    overflow-y: auto;
    width: calc(100% - 35px);
    padding-left: 20px;
    padding-right: 10px;
    max-height: calc(60vh - 50px);
    margin-bottom: 15px;
}
.dialogContentContainer {
    scrollbar-width: thin;
    scrollbar-color: #787878 transparent;
    margin-right: 1px;
}
.dialogContentContainer::-webkit-scrollbar {
    width: 4px;
}
.dialogContentContainer::-webkit-scrollbar-track {
    background: transparent;
}
.dialogContentContainer::-webkit-scrollbar-thumb {
    background: #787878;
    border-radius: 2px;
}
.dialogContentContainer::-webkit-scrollbar-thumb:hover {
    background: #4f4f4f;
}
