.container,
.textEditorContainer {
    width: fit-content;
    max-width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 5px;
    width: calc(100% - 20px);
}

.textEditorContainer {
    max-width: '100%';
    display: flex;
    flex-direction: column;
}
.postViewContainer {
    height: fit-content;
    border-radius: 15px;
    min-height: 265px;
    width: 100%;
    position: relative;
    margin-bottom: 1px;
    overflow: hidden;
}
.textContainer {
    height: 100%;
    border-radius: 15px;
    height: 265px;
    width: 100%;
    position: relative;
    margin-bottom: 1px;
    overflow: hidden;
}

.editor {
    margin: -5px 10px 0 10px;
    height: calc(100% - 22px) !important;
}

.wrapper {
    height: 100%;
    border-radius: 15px;
}

.errorMsg {
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 14px;
    font-size: 0.75rem;
    height: 0.75rem;
}

.textEditorLabel {
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 1rem;
}
.print {
    display: none;
    position: relative;
}
.printLbl {
    padding: 0px 4px;
    top: -8px;
    left: 10.5px;
    font-size: 0.75rem;
    position: absolute;
    z-index: 3;
}
.emptyPrintLbl {
    position: absolute;
    top: 9px;
    left: 14px;
    font-size: 1rem;
}
.printLine {
    top: 0px;
    padding: 0px 5px;
    left: 9px;
    height: 2px;
    font-size: 0.75rem;
    position: absolute;
    overflow: hidden;
    z-index: 2;
}
.printContainer {
    border-radius: 10px;
    border: 1px solid black;
    padding: 5px 11px;
    width: 100%;
    max-width: 100%;
    font-size: 1rem;
    min-height: 76px;
    line-height: 1.2rem;
    margin: 0px;
}
.erkTextArea {
    position: relative;
    width: 100%;
    max-width: 100%;
}

@media print {
    .print {
        display: flex;
        width: 100%;
        max-width: 100%;
    }
    .erkTextArea {
        display: none !important;
    }
}
