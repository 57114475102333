.container {
    box-shadow:
        0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(27, 26, 26, 0.12);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: white;
    width: 130px;
    padding: 0px 35px 30px 30px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 15px;
}

.errorMessage {
    font-size: 0.8em;
    top: 0;
    min-height: 14px;
    padding-top: 5px;
    margin-bottom: -1px;
    margin-left: -16px;
    margin-right: -20px;
}

.numberPickerContainer {
    width: 130px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.workingContainer {
    display: flex;
    font-size: 1.1em;
    margin-bottom: -5px;
    margin-left: 5px;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
