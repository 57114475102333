.hidden-input {
    height: 0;
    width: 0;
    padding: 0;
    border-width: 0;
}

.Erk-Forms-Confirmation {
    text-align: center;
    pointer-events: none;
}

.Erk-Forms-Confirmation {
    .public-DraftStyleDefault-block {
        margin: 0;
    }
    div {
        text-align: center;
    }
}
